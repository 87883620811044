import React from "react";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useGetLink } from "../../utils/useOpenLink";
import CustomLink from "../../utils/customLink";
import PlayIconSvg from "../../assets/PlayIconSvg";

function ImageCard({ content, colorsObj }) {
    const { image, title, posterImage, content_source, type, path, contentLink, link, language } =
        content;

    const { getLink } = useGetLink();

    return (
        <div className="card">
            <div className="cardImg">
                {!!link && (
                    <div
                        className="playIconHldr"
                        style={{
                            "--accent_color4": colorsObj.accent_color4,
                        }}
                    >
                        <PlayIconSvg />
                    </div>
                )}
                <ImageWithObjectFit className="cursor-pointer" src={image?.src || posterImage} />
            </div>
            <CustomLink
                to={getLink(content_source === "Content" ? contentLink : path, type, language)}
                type={type}
            >
                <div className="card-body">
                    <h3 className="cursor-pointer">{title}</h3>
                </div>
            </CustomLink>
        </div>
    );
}

export default ImageCard;
