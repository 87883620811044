import ImageWithObjectFit from "../ImageWrapperComponent";
import "./HeroSectionLeft.css";
import parse from "html-react-parser";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import * as posterImage from "../../utils/usePosterImage";
import { APP_COLORS } from "../../constants/styles";
import CustomLink from "../../utils/customLink";
import { ARTICLE_DETAILS, BLOCK_DETAILS } from "../../constants/strings";

function HeroSectionLeft({
    contents: contentToRender,
    heading,
    body,
    section_image,
    colors: colorObj,
    block_buttons,
    block_source,
}) {
    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    const [heroSectionLeftDetails, setHeroSectionLeftDetails] = useState({
        heading: "",
        body: "",
        path: "",
        image: "",
        nid: "",
    });

    useEffect(() => {
        if (block_source === BLOCK_DETAILS) {
            setHeroSectionLeftDetails({
                ...heroSectionLeftDetails,
                heading: heading,
                body: body,
                image: section_image?.src,
            });
        }

        if (block_source === ARTICLE_DETAILS) {
            setHeroSectionLeftDetails({
                ...heroSectionLeftDetails,
                heading: contentReferenceData[0]?.title,
                body: contentReferenceData[0]?.summary,
                path: contentReferenceData[0]?.path,
                image: contentReferenceData[0]?.image?.src || contentReferenceData[0]?.posterImage,
                nid: contentReferenceData[0]?.nid,
            });
        }
    }, [
        body,
        section_image?.src,
        block_source,
        heading,
        contentReferenceData[0]?.title,
        contentReferenceData[0]?.summary,
        contentReferenceData[0]?.image?.src,
        contentReferenceData[0]?.posterImage,
    ]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = contentFromStore[contentKey];
            let resp = respKeys?.map((k) => contentFromStore[k]) || [];
            resp = JSON.parse(JSON.stringify(resp));
            for await (const element of resp) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        setCurrentContent(contentToRender?.[0]?.contentKey || "");
    }, [contentToRender, setCurrentContent]);

    return (
        <>
            <div
                className="heroSectionLeftCardWrapper md:py-30 text-start"
                style={APP_COLORS(colorObj)}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="newsCardBig">
                                <div className="row flex justify-center container">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 card-order flex items-center">
                                        <div className="card-body">
                                            <div
                                                className="heading"
                                                style={{ color: colorObj?.primary_color }}
                                            >
                                                {heroSectionLeftDetails.heading}
                                            </div>
                                            <div
                                                className="summaryText"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                {heroSectionLeftDetails.body &&
                                                    parse(heroSectionLeftDetails.body)}
                                            </div>
                                            <>
                                                {block_source === BLOCK_DETAILS
                                                    ? block_buttons?.length > 0 && (
                                                          <div className="mt-auto">
                                                              {block_buttons.map((v) => (
                                                                  <CustomLink
                                                                      to={getLink(v.action)}
                                                                      key={v.label}
                                                                  >
                                                                      <div
                                                                          className="btn btn-link button me-3"
                                                                          style={{
                                                                              "--primary_color":
                                                                                  colorObj?.primary_color,
                                                                              "--background_color":
                                                                                  colorObj?.background_color,
                                                                          }}
                                                                      >
                                                                          {v.label}
                                                                      </div>
                                                                  </CustomLink>
                                                              ))}
                                                          </div>
                                                      )
                                                    : contentToRender.length > 0 && (
                                                          <CustomLink
                                                              to={getLink(
                                                                  heroSectionLeftDetails?.path,
                                                                  heroSectionLeftDetails?.type,
                                                                  heroSectionLeftDetails?.language,
                                                              )}
                                                          >
                                                              <div
                                                                  className="btn btn-link button me-3"
                                                                  style={{
                                                                      "--primary_color":
                                                                          colorObj?.primary_color,
                                                                      "--background_color":
                                                                          colorObj?.background_color,
                                                                  }}
                                                              >
                                                                  {t("btnText.learnMore")}
                                                              </div>
                                                          </CustomLink>
                                                      )}
                                            </>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 lg:pe-0 md:pe-0">
                                        <div className="NewsImg">
                                            <ImageWithObjectFit
                                                src={heroSectionLeftDetails.image}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroSectionLeft;
