export const additionalClassNameFromLanguage = (language) => {
    switch (language) {
        case "Tajik":
            return "tajik-language-body";
        case "Urdu":
            return "urdu-language-body-line-height";
        default:
            return "";
    }
};

export const addLineClassNameFromLanguage = (languageCode) => {
    switch (languageCode) {
        case "ur":
            return "urdu-language-margin-bottom";
        default:
            return "default-margin-bottom";
    }
};
