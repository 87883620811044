import React, { useEffect, useState } from "react";
import "./SubscribeNewsLetter.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { ALPHABET_NUMBER_SPACE_SOME_SPECIAL_PATTERN, EMAIL_PATTERN } from "../../utils/pattern";
import { Checkbox } from "primereact/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { fetchNewsLetterFormFields, saveNewsLetterForm } from "../../redux/newsLetterFormSlice";
import { Calendar } from "primereact/calendar";
import parse from "html-react-parser";
import { Toast } from "primereact/toast";
import useToast from "../../utils/useToast";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import Loader from "../Loader/loader";
import "react-phone-input-2/lib/style.css";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { removeSpaceOnFirstCharacter } from "../../utils/spaceNotAllowedOnFirstCharacter";
import { verifyPhoneNumber } from "../../utils/verifyPhoneNumber";

function SubscribeNewsLetter({ heading, body, contents }) {
    const [formValues, setFormValues] = useState({});
    const { toastRef, showToast } = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const { t } = useCustomTranslation();

    const dispatch = useDispatch();
    const formFields = useSelector((state) => state.newsLetterForms.formFields);
    const isLoading = useSelector((state) => state.newsLetterForms.isLoading);

    const link = contents?.[0]?.content_reference;

    useEffect(() => {
        if (link) dispatch(fetchNewsLetterFormFields(link));
    }, [dispatch, link]);

    useEffect(() => {
        if (formFields && formFields.length > 0) {
            const initialFormValues = formFields.reduce((acc, { pk, field_type }) => {
                acc[`field_${pk}`] = field_type === "checkbox" ? 0 : "";
                return acc;
            }, {});
            setFormValues(initialFormValues);
        }
    }, [formFields]);

    const validateForm = () => {
        const errors = {};

        formFields.forEach(({ pk, field_type, required }) => {
            const fieldValue = formValues[`field_${pk}`];

            if (
                field_type === "text" ||
                field_type === "email" ||
                field_type === "memo" ||
                field_type === "phone" ||
                field_type === "year" ||
                field_type === "date" ||
                field_type === "select"
            ) {
                if (required === 1 && !fieldValue)
                    errors[`field_${pk}`] = t("constantString.required_field_text");
                else if (
                    field_type === "text" &&
                    fieldValue &&
                    !ALPHABET_NUMBER_SPACE_SOME_SPECIAL_PATTERN.test(fieldValue)
                )
                    errors[`field_${pk}`] = t("constantString.valid_data");
                else if (field_type === "email" && !EMAIL_PATTERN.test(fieldValue))
                    errors[`field_${pk}`] = t("constantString.valid_data");
                else if (field_type === "memo" && fieldValue.length < 3)
                    errors[`field_${pk}`] = t("constantString.three_charaters_long");
            }

            if (field_type === "checkbox" && required === 1 && !fieldValue) {
                errors[`field_${pk}`] = t("constantString.required_checkbox_text");
            }
        });
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        const fieldPk = name.replace("field_", "");
        const field = formFields.find((f) => f.pk === Number(fieldPk));
        const { field_type, required } = field || {};
        let errors = { ...formErrors };

        setFormValues({
            ...formValues,
            [name]: field_type === "checkbox" ? (checked ? 1 : 0) : value, // Use ternary to handle checkbox as 1/0
        });

        if (field) {
            if (
                field_type === "text" ||
                field_type === "email" ||
                field_type === "memo" ||
                field_type === "phone" ||
                field_type === "year" ||
                field_type === "date" ||
                field_type === "select"
            ) {
                if (!value && required === 1) {
                    errors[`field_${fieldPk}`] = t("constantString.required_field_text");
                } else if (
                    field_type === "text" &&
                    value &&
                    !ALPHABET_NUMBER_SPACE_SOME_SPECIAL_PATTERN.test(value)
                ) {
                    errors[`field_${fieldPk}`] = t("constantString.valid_data");
                } else if (field_type === "email" && !EMAIL_PATTERN.test(value)) {
                    errors[`field_${fieldPk}`] = t("constantString.valid_data");
                } else if (field_type === "memo" && value.length < 3) {
                    errors[`field_${fieldPk}`] = t("constantString.three_charaters_long");
                } else {
                    delete errors[`field_${fieldPk}`];
                }
            }

            if (field_type === "checkbox" && !value && required === 1) {
                errors[`field_${fieldPk}`] = t("constantString.required_checkbox_text");
            } else if (field_type === "date") {
                delete errors[`field_${fieldPk}`];
            }
        }

        setFormErrors(errors);
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        const payload = {};
        formFields.forEach(({ pk, field_type, values }) => {
            let fieldValue = formValues[`field_${pk}`] || "";

            if (field_type === "hidden") {
                fieldValue = values[0];
            } else if (field_type === "year" && fieldValue) {
                fieldValue = new Date(fieldValue).getFullYear();
            } else if (field_type === "date" && fieldValue) {
                fieldValue = moment(fieldValue).format("DD/MM/YYYY");
            }

            payload[pk] = fieldValue;
        });

        const data = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
            data.append(key, value);
        });

        try {
            const result = await dispatch(saveNewsLetterForm({ formData: data }));

            if (result.payload.success) {
                showToast("success", "Success", result.payload.message);
                setFormValues({
                    name: "",
                    email: "",
                    country: "",
                    subject: "",
                    message: "",
                });
            } else {
                showToast("error", "Error", "An error occurred while submitting the form.");
            }
        } catch (error) {
            showToast("error", "Error", "An error occurred while submitting the form.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const onChangePhoneInput = (phoneNumber, name, countryData) => {
        const fieldPk = name.replace("field_", "");
        const field = formFields.find((f) => f.pk === Number(fieldPk));
        const { required } = field || {};

        const newCountryCode = `+${countryData.dialCode}`;
        const currentPhoneNumber = phoneNumber || "";
        const countryCodeMatch = currentPhoneNumber.match(/^\+?\d+/);
        const previousCountryCode = countryCodeMatch ? countryCodeMatch[0] : "";

        let newPhoneNumber = currentPhoneNumber;
        if (previousCountryCode !== newCountryCode) {
            newPhoneNumber = newCountryCode + " ";
        }

        setFormValues({
            ...formValues,
            [name]: newPhoneNumber,
        });

        // Validate using `verifyPhoneNumber`
        const isValid = verifyPhoneNumber(currentPhoneNumber, countryData, required);

        setFormErrors((prevErrors) => {
            const newErrors = { ...prevErrors };

            if (!newPhoneNumber.trim()) {
                newErrors[`field_${fieldPk}`] = t("constantString.required_field_text"); // Required validation
            } else if (!isValid) {
                newErrors[`field_${fieldPk}`] = t("constantString.valid_data"); // Format validation
            } else {
                delete newErrors[`field_${fieldPk}`]; // Remove error if valid
            }

            return newErrors;
        });
    };

    return (
        <>
            <Loader isLoading={isLoading} />

            <Toast ref={toastRef} />

            <div className="container NewsLetterFormWrapper">
                <div className="row">
                    <div className="col-12 mb-12">
                        <h2>{heading}</h2>
                    </div>

                    <div className="col-12 mb-12">{parse(body)}</div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="row">
                            {formFields.map(({ title, field_type, required, values, pk }) => (
                                <div
                                    className={`col-12 ${
                                        field_type !== "email" && field_type !== "checkbox"
                                            ? "col-lg-6"
                                            : ""
                                    } mb-6`}
                                    key={pk}
                                >
                                    <div className="flex flex-col gap-2">
                                        {field_type !== "checkbox" && (
                                            <label className="block text-gray-700 text-lg">
                                                {title} {required === 1 && <sup>*</sup>}
                                            </label>
                                        )}
                                        {field_type === "text" && (
                                            <InputText
                                                name={`field_${pk}`}
                                                value={formValues[`field_${pk}`] || ""}
                                                onChange={handleInputChange}
                                                onKeyDown={(event) => {
                                                    removeSpaceOnFirstCharacter(event);
                                                }}
                                            />
                                        )}
                                        {field_type === "email" && (
                                            <InputText
                                                name={`field_${pk}`}
                                                value={formValues[`field_${pk}`] || ""}
                                                onChange={handleInputChange}
                                                onKeyDown={(event) => {
                                                    removeSpaceOnFirstCharacter(event);
                                                }}
                                            />
                                        )}
                                        {field_type === "phone" && (
                                            <PhoneInput
                                                name={`field_${pk}`}
                                                enableSearch={true}
                                                disableSearchIcon={true}
                                                country={"us"}
                                                value={formValues[`field_${pk}`] || ""}
                                                onChange={(value, countryData, formattedValue) => {
                                                    onChangePhoneInput(
                                                        value,
                                                        `field_${pk}`,
                                                        countryData,
                                                        formattedValue,
                                                    );
                                                }}
                                            />
                                        )}
                                        {field_type === "year" && (
                                            <Calendar
                                                view="year"
                                                value={formValues[`field_${pk}`] || ""}
                                                dateFormat="yy"
                                                onChange={handleInputChange}
                                                id={`field_${pk}`}
                                                name={`field_${pk}`}
                                            />
                                        )}
                                        {field_type === "date" && (
                                            <Calendar
                                                value={formValues[`field_${pk}`] || ""}
                                                dateFormat="dd/mm/yy"
                                                onChange={handleInputChange}
                                                id={`field_${pk}`}
                                                name={`field_${pk}`}
                                            />
                                        )}
                                        {field_type === "select" && (
                                            <Dropdown
                                                name={`field_${pk}`}
                                                value={formValues[`field_${pk}`] || ""}
                                                options={values.map((value) => ({
                                                    label: value,
                                                    value,
                                                }))}
                                                onChange={handleInputChange}
                                                placeholder={`Select ${title}`}
                                                className="w-full md:w-14rem"
                                            />
                                        )}
                                        {field_type === "checkbox" && (
                                            <>
                                                <div className="flex">
                                                    <Checkbox
                                                        id={`field_${pk}`}
                                                        checked={formValues[`field_${pk}`] === 1} // Checkbox is checked if value is 1
                                                        onChange={handleInputChange}
                                                        name={`field_${pk}`}
                                                    />
                                                    <label htmlFor={`field_${pk}`} className="ml-2">
                                                        {parse(title)}
                                                    </label>
                                                </div>
                                            </>
                                        )}

                                        {formErrors[`field_${pk}`] && (
                                            <small className="p-error">
                                                {formErrors[`field_${pk}`]}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            ))}

                            <div className="col-12">
                                <div className="flex flex-col gap-2">
                                    <button
                                        type="button"
                                        className="button"
                                        onClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubscribeNewsLetter;
